<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }"
                    >首页
                    </el-breadcrumb-item
                    >
                    <el-breadcrumb-item>交易查询</el-breadcrumb-item>
                    <el-breadcrumb-item>商户日汇总</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="交易起止日期">
                            <el-date-picker
                                    v-model="date"
                                    @change="changeDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                                    :clearable="true"
                                    unlink-panels
                                    :picker-options="pickerOptions"
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="商户ID">
                            <el-input
                                    v-model="where.obj.merchantPlatId"
                                    placeholder="请输入商户ID"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="门店ID">
                            <el-input
                                    v-model="where.obj.shopId"
                                    placeholder="请输入门店ID"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="BD姓名">
                            <el-select v-model="where.obj.bdName" clearable filterable placeholder="BD姓名">
                                <el-option label="全部" value=""></el-option>
                                <el-option
                                        v-for="item in bdNamesOption"
                                        :key="item.id"
                                        :label="`${item.name}(${item.id})`"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="服务商姓名">
                            <el-select v-model="where.obj.serviceName" clearable filterable placeholder="服务商姓名">
                                <el-option label="全部" value=""></el-option>
                                <el-option
                                        v-for="item in serviceNamesOption"
                                        :key="item.id"
                                        :label="`${item.name}(${item.id})`"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支付类型">
                            <el-select v-model="where.obj.payType" placeholder="支付类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option
                                        v-for="(name,value) in payTypeMap"
                                        :key="value"
                                        :label="name"
                                        :value="value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支付方式">
                            <el-select v-model="where.obj.payWay" placeholder="支付类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option
                                        v-for="(name,value) in payWayMap"
                                        :key="value"
                                        :label="name"
                                        :value="value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客单价不小于">
                            <el-input-number
                                    :min="0.01"
                                    v-model="where.obj.amount"
                                    :controls="false"
                                    placeholder="请输入最小金额">
                            </el-input-number>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="()=>{currentPage=1;listData();}"
                            >条件筛选
                            </el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    icon="el-icon-tickets"
                                    :loading="download"
                            >导出数据
                            </el-button
                            >
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                >
                    <el-table-column label="序号" width="50px">
                        <template slot-scope="scope">
                            {{ scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="shopId" label="门店ID"></el-table-column>
                    <el-table-column prop="shopName" label="门店名"></el-table-column>
                    <el-table-column prop="merchantPlatId" label="商户ID"></el-table-column>
                    <el-table-column prop="companyName" label="商户名"></el-table-column>
                    <el-table-column prop="realName" label="真实姓名"></el-table-column>
                    <el-table-column prop="approveDate" label="商户认证时间"></el-table-column>
                    <el-table-column prop="merchantType" label="商户类型"></el-table-column>
                    <el-table-column prop="bdId" label="BDID"></el-table-column>
                    <el-table-column prop="bdName" label="BD姓名"></el-table-column>
                    <el-table-column prop="serviceId" label="服务商ID"></el-table-column>
                    <el-table-column prop="serviceName" label="服务商姓名"></el-table-column>
                    <el-table-column prop="totalAmount" label="总金额"></el-table-column>
                    <el-table-column prop="orderNum" label="总笔数"></el-table-column>
                    <el-table-column prop="orderNumFilter" label=">=2交易笔数"></el-table-column>
                    <el-table-column prop="personNum" label="总人数"></el-table-column>
                    <el-table-column prop="averageAmount" label="客单价（汇总金额/笔数）"></el-table-column>
                    <el-table-column prop="personOrderRate" label="交易人数/交易笔数（%）"></el-table-column>
                    <el-table-column prop="userBeanCount" label="用户惠豆"></el-table-column>
                    <el-table-column prop="merchantBeanCount" label="商家惠豆"></el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {payTypeMap, payWayMap} from "@/plugins/map.js";
    import {exportFile, formatDate, pickerOptions} from "@/plugins/function.js";

    export default {
        components: {},
        props: {},
        data() {
            return {
                payTypeMap,
                payWayMap,
                pickerOptions,
                date: "",
                list: [],
                bdNamesOption: [],
                serviceNamesOption: [],
                loading: false,
                download: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                where: {
                    pageNum: 0,
                    pageSize: 0,
                    obj: {
                        payType: "",
                        payWay: "",
                        bdName: "",
                        serviceName: ""
                    }
                },
            };
        },
        methods: {
            listOption() {
                this.$post(this.$api.bdNamesOption, {}).then((res) => {
                    this.bdNamesOption = res.data;
                });
                this.$post(this.$api.serviceNamesOption, {}).then((res) => {
                    this.serviceNamesOption = res.data;
                });
            },
            listData() {
                this.loading = true;
                this.where.obj.createStartDate = this.date ? this.date[0] : "";
                this.where.obj.createEndDate = this.date ? this.date[1] : "";
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.traderTotalDay, this.where).then((res) => {
                    this.list = res.data.records;
                    this.totalData = res.data.total;
                    this.currentPage = res.data.current;
                    this.loading = false;
                });
            }
            , exportData() {
                // 报表“导出”功能需要根据BD或者服务商导出所属商户的信息
                console.log(this.where.obj.bdName, this.where.obj.serviceName)
                if (!this.where.obj.bdName && !this.where.obj.serviceName) {
                    this.$message({
                        type: "error",
                        message: "请填写BD或者服务商姓名",
                    });
                    return;
                }
                this.download = true;
                this.where.obj.createStartDate = this.date ? this.date[0] : "";
                this.where.obj.createEndDate = this.date ? this.date[1] : "";
                this.$post(this.$api.traderTotalDayExport, {...this.where, export: true}, true).then((res) => {
                    this.download = false;
                    exportFile(res.data, decodeURI(res.headers.filename));
                });
            },
            changeDate(obj) {
                if (obj) {
                    this.where.obj.createStartDate = obj[0];
                    this.where.obj.createEndDate = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.obj.createStartDate = '';
                        this.where.obj.createEndDate = '';
                    }
                });
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
        },
        created() {
            this.date = [];
            let now = new Date();
            // this.date.push(formatDate(new Date(now.getFullYear(), now.getMonth(), 1),"yyyy-MM-dd"));
            this.date.push(formatDate(now, "yyyy-MM-dd"));
            this.date.push(formatDate(now, "yyyy-MM-dd"));
            this.listOption();
            this.listData();
        },
    };
</script>
<style lang="scss" scoped>
    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }
</style>
<style>
    .el-drawer__header span:focus {
        outline: 0 !important;
    }
</style>
